import React, { useEffect, useState } from "react"
import { Tabs, Tag } from "antd"
import { Link } from "gatsby"
import MiniAppHeader from "@/components/common/MiniAppHeader"
import * as style from "./style.module.scss"

const StakeLayout = ({ children, location }: { children: React.ReactNode; location: Location }) => {
  const section = !location.pathname.startsWith("/stake/ada/") ? "tokens" : "ada"

  return (
    <div>
      <MiniAppHeader id="xray-stake" />
      <div className="xray-page">
        <div>
          <Tabs
            className="xray-tabs-link"
            activeKey={section}
            items={[
              {
                key: "ada",
                label: (
                  <strong>
                    <Link to="/stake/ada/">ADA Staking</Link>
                  </strong>
                ),
              },
              {
                key: "tokens",
                label: (
                  <strong>
                    Tokens Staking <sup>soon</sup>
                  </strong>
                ),
                disabled: true,
              },
            ]}
          />
        </div>
        <div>
          {section === "ada" && (
            <Tabs
              className="xray-tabs-link"
              activeKey={location.pathname}
              items={[
                {
                  key: "/stake/ada/",
                  label: (
                    <strong>
                      <Link to="/stake/ada/">My Position</Link>
                    </strong>
                  ),
                },
                {
                  key: "/stake/ada/pools/",
                  label: (
                    <strong>
                      <Link to="/stake/ada/pools/">Pools List</Link>
                    </strong>
                  ),
                },
                {
                  key: "/stake/ada/xray-pools/",
                  label: (
                    <strong>
                      <Link to="/stake/ada/xray-pools/">XRAY Pools</Link>
                    </strong>
                  ),
                },
                {
                  key: "/stake/ada/promoted/",
                  label: (
                    <strong>
                      <Link to="/stake/ada/promoted/">Promoted</Link>
                    </strong>
                  ),
                },
                {
                  key: "/stake/ada/bookmarks/",
                  label: (
                    <strong>
                      <Link to="/stake/ada/bookmarks/">Bookmarks</Link>
                    </strong>
                  ),
                },
              ]}
            />
          )}
        </div>
        <div className="pt-3">{children}</div>
      </div>
    </div>
  )
}

export default StakeLayout
