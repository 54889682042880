import React, { useEffect, useState } from "react"
import { Modal, Button, Switch, Radio, Select, Input, Popconfirm, Statistic } from "antd"
import { navigate } from "gatsby"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import * as style from "./style.module.scss"
import { AppActions, AppTypes } from "@/redux/app"
import { AccountActions, AccountSelectors, AccountTypes } from "@/redux/account"
import Informers from "@/components/informers"
import NetworkStats from "@/components/common/NetworkStats"
import * as utils from "@/utils"
import { ConfigTypes } from "@/config"

const AccountSettings = () => {
  const dispatch = useAppDispatch()
  const currentAccount = useAppSelector(AccountSelectors.currentAccountExtended)
  const exportData = useAppSelector(AccountSelectors.currentAccount)

  const exportAccountData = () => {
    const blob = new Blob([JSON.stringify(exportData, undefined, 2)], {
      type: "application/json",
    })
    const a = document.createElement("a")
    a.href = URL.createObjectURL(blob)
    a.download = `${currentAccount!.web3export.paymentAddress}.key`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(a.href)
  }

  return (
    <>
      {currentAccount && (
        <div>
          {/* <div className="mb-4">
            <strong>Account Settings</strong>
          </div> */}
          <div className="mb-4">
            <div>
              <span className="d-flex align-items-center">
                <div className="me-1">
                  <div>Account Name</div>
                  <div className="font-size-14 text-muted d-none d-sm-block">
                    Set a custom name for the current account
                  </div>
                </div>
                <span className="ms-auto text-nowrap align-self-start">
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    value={currentAccount.name.type}
                    options={[
                      {
                        label: "Address",
                        value: "address",
                      },
                      {
                        label: "Custom",
                        value: "custom",
                      },
                      {
                        label: "$Handle",
                        value: "handle",
                      },
                    ]}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        AccountActions.NAME_UPDATE_SAGA({
                          ...currentAccount.name,
                          type: value,
                        })
                      )
                    }}
                  />
                </span>
              </span>
            </div>
            {currentAccount.name.type === "custom" && (
              <div className="my-3">
                <span className="d-flex align-items-center">
                  <span>Custom Name</span>
                  <span className="ms-auto">
                    <Input
                      value={currentAccount.name.custom}
                      placeholder="Set name..."
                      onChange={(value) => {
                        dispatch(
                          AccountActions.NAME_UPDATE_SAGA({
                            ...currentAccount.name,
                            custom: value.target.value,
                          })
                        )
                      }}
                    />
                  </span>
                </span>
              </div>
            )}
            {currentAccount.name.type === "handle" && (
              <div className="my-3">
                <span className="d-flex align-items-center">
                  <span>$Handle Name</span>
                  <span className="ms-auto">
                    {currentAccount.handles.length > 0 && (
                      <Select
                        value={currentAccount.name.handle || undefined}
                        popupMatchSelectWidth={false}
                        placeholder="Select handle"
                        onChange={(value) => {
                          dispatch(
                            AccountActions.NAME_UPDATE_SAGA({
                              ...currentAccount.name,
                              handle: value,
                            })
                          )
                        }}
                      >
                        {currentAccount.handles.map((handle) => {
                          return (
                            <Select.Option key={handle} value={handle}>
                              {handle}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    )}
                    {currentAccount.handles.length < 1 && <Select disabled placeholder="No handles found" />}
                  </span>
                </span>
              </div>
            )}
            <div className="xray-line xray-line-dashed my-4" />
            <div className="d-flex align-items-center">
              <div className="me-1">
                <div>Export .key file</div>
                <div className="font-size-14 text-muted d-none d-sm-block">
                  Export the .key file if you want to back up your account
                </div>
              </div>
              <div className="ms-auto">
                <Popconfirm
                  placement="topRight"
                  title="Export account data?"
                  onConfirm={exportAccountData}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button shape="round">
                    <i className="xi xi-download" />
                    <span>Export</span>
                  </Button>
                </Popconfirm>
              </div>
            </div>
            <div className="xray-line xray-line-dashed my-4" />
            <div className="d-flex align-items-center">
              <div className="me-1">
                <div>Delete account</div>
                <div className="font-size-14 text-muted d-none d-sm-block">
                  Permanently delete the current account data from this device
                </div>
              </div>
              <div className="ms-auto">
                <Popconfirm
                  placement="topRight"
                  title="Delete account data?"
                  onConfirm={() => {
                    dispatch(AccountActions.DELETE_ACCOUNT_SAGA(currentAccount.id))
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button shape="round" type="default" variant="outlined" color="danger">
                    <i className="xi xi-trash" />
                    <span>Delete</span>
                  </Button>
                </Popconfirm>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AccountSettings
